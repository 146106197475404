//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard, XDown } from '@/components'
import { sysOpLogPage, sysOpLogDelete, sysOpLogExport } from '@/api/modular/system/logManage'
import detailsOplog from './details'
import moment from 'moment'
export default {
  components: {
    XDown,
    XCard,
    STable,
    Ellipsis,
    detailsOplog
  },
  data () {
    return {
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '日誌名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '操作類型',
          dataIndex: 'opType',
          scopedSlots: { customRender: 'opType' }
        },
        {
          title: '執行結果',
          dataIndex: 'success',
          scopedSlots: { customRender: 'success' }
        },
        {
          title: 'ip',
          dataIndex: 'ip'
        },
        {
          title: '請求地址',
          dataIndex: 'url',
          scopedSlots: { customRender: 'url' }
        },
        {
          title: '操作時間',
          dataIndex: 'opTime',
          scopedSlots: { customRender: 'opTime' }
        },
        {
          title: '操作人',
          dataIndex: 'account'
        },
        {
          title: '詳情',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysOpLogPage(Object.assign(parameter, this.switchingDate())).then((res) => {
          return res.data
        })
      },
      opTypeDict: [],
      successDict: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
  },
  methods: {
    moment,
    /**
     * 查詢參數組裝
     */
    switchingDate () {
      const dates = this.queryParam.dates
      if (dates != null) {
        this.queryParam.searchBeginTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.searchEndTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
        if (dates.length < 1) {
          delete this.queryParam.searchBeginTime
          delete this.queryParam.searchEndTime
        }
      }
      const obj = JSON.parse(JSON.stringify(this.queryParam))
      delete obj.dates
      return obj
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.opTypeDict = this.$options.filters['dictData']('op_type')
      this.successDict = this.$options.filters['dictData']('yes_or_no')
    },
    /**
     * 清空日誌
     */
    sysOpLogDelete () {
      sysOpLogDelete().then((res) => {
        if (res.success) {
          this.$message.success('清空成功')
          this.$refs.table.refresh(true)
        } else {
          this.$message.error('清空失敗：' + res.message)
        }
      })
    },
    /**
     * 批量導出
     */
    batchExport () {
      sysOpLogExport().then((res) => {
        this.$refs.batchExport.downloadfile(res)
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    }
  }
}
